import React from "react"
import styled from "styled-components"
import Slider from "react-slick"
import Img from "gatsby-image"
import sal from "sal.js"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
}

const SliderPanel = styled.div``

const Panel = styled.div`
  & span {
    color: var(--text);
  }
  & div {
    background: var(--surfaceTr);
  }
  & .img {
    border-radius: none !important;
  }
`

const StyledBg = styled.div`
  background: var(--surfaceBg);
  background-size: cover !important;
`

const Desc = styled.div`
  color: var(--text);
`

const ProductPalette = props => {
  React.useEffect(() => {
    sal()
  }, [])

  return (
    <div
      className="pt-5 pb-5 ml-4 mr-4 md:ml-10 md:mr-10 relative"
      data-sal-duration="500"
      data-sal="slide-up"
      data-sal-easing="ease"
    >
      <StyledBg className="w-full absolute left-0 bottom-0 h-full z-30 rounded-xl" />
      <Panel className="text-center relative pb-10 z-30">
        <span className="text-3xl block pb-5">Products</span>
        <span className="block pl-5 pr-5 md:pl-32 md:pr-32 mx-auto text-sm">
          We distribute a wide range of industrial as well as consumer devices,
          the deconta-connect integration is growing steadily. Subscribe to our
          newsletter to stay up to date.
        </span>
      </Panel>
      <Panel className="w-10/12 md:w-full relative pb-5 mx-auto md:m-0 z-30 deconta-product-crousel">
        <Slider {...sliderSettings} className="rounded-xl md:ml-10 md:mr-10">
          <SliderPanel className="md:ml-3 md:mt-3 md:mb-2 md:mr-3 index-slider">
            <div className="pt-5 md:pt-0 text-center">
              <Img
                fixed={props.R150Office_Img}
                className="
                  rounded-full
                  md:rounded-tl-xl md:rounded-bl-xl md:rounded-tr-none md:rounded-br-none
                  img
                "
              />
            </div>
            <Desc className="w-full pb-5 md:pb-0 pl-5 pr-5 pt-1 md:max-h-72 md:overflow-y-auto">
              <h3 className="text-xl pb-2 text-center md:text-left">
                Air purifier
              </h3>
              <span className="text-sm">
                Clean air - like clean water, is the basis of a healthy life.
                Nevertheless, we are surrounded by viruses, bacteria, dust,
                pollen and many other tiny particles every day. We cannot see
                them, but they are present in every room. Especially where many
                different people come together, e.g. in sports clubs, waiting
                and treatment rooms, shops, churches, schools and offices, but
                also in your home. With a professional air purifier from deconta
                you not only reduce the risk of a virus infection and the
                release of ultra-fine particles and dust, but you also achieve a
                significantly lower exposure to allergies such as hay fever and
                a significantly lower exposure to unpleasant odors.
              </span>
            </Desc>
          </SliderPanel>
          <SliderPanel className="md:ml-3 md:mt-3 md:mb-2 md:mr-3 index-slider">
            <div className="pt-5 md:pt-0 text-center">
              <Img
                fixed={props.G100_Img}
                className="
                  rounded-full
                  md:rounded-tl-xl md:rounded-bl-xl md:rounded-tr-none md:rounded-br-none
                  img
                "
              />
            </div>
            <Desc className="w-full pb-5 md:pb-0 pl-5 pr-5 pt-1 md:max-h-72 md:overflow-y-auto">
              <h3 className="text-xl pb-2 text-center md:text-left">
                NPU GREEN DEC SERIES
              </h3>
              <span className="text-sm">
                Negative pressure unit for filtering asbestos contaminated room
                air via a 3-stage filter unit.
                <ul className="text-sm">
                  <li className="p-0.5">
                    - energy-efficient and performance-optimized fan with high
                    capacity
                  </li>
                  <li className="p-0.5">
                    - smooth and easy-to-decontaminate housing
                  </li>
                  <li className="p-0.5">- light and sturdy housing</li>
                  <li className="p-0.5">- threefold sealed HEPA filter</li>
                  <li className="p-0.5">
                    - easy, very low-emission filter change in 3 minutes
                  </li>
                  <li className="p-0.5">
                    - automatic soft start, even after power failure
                  </li>
                  <li className="p-0.5">
                    - all operating control and functional elements are
                    shock-protected
                  </li>
                  <li className="p-0.5">
                    - stackable housings, thus low space requirement
                  </li>
                </ul>
              </span>
            </Desc>
          </SliderPanel>
          <SliderPanel className="md:ml-3 md:mt-3 md:mb-2 md:mr-3 index-slider">
            <div className="pt-5 md:pt-0 text-center">
              <Img
                fixed={props.S200_Img}
                className="
                  rounded-full
                  md:rounded-tl-xl md:rounded-bl-xl md:rounded-tr-none md:rounded-br-none
                  img
                "
              />
            </div>
            <Desc className="w-full pb-5 md:pb-0 pl-5 pr-5 pt-1 md:max-h-72 md:overflow-y-auto">
              <h3
                className="
                  text-xl
                  pb-2
                  text-center md:text-left
                "
              >
                NPU SMART DEC SERIES
              </h3>
              <span className="text-sm">
                Negative pressure unit for filtering asbestos contaminated room
                air via a 3-stage filter unit.
                <ul className="text-sm">
                  <li className="p-0.5">
                    - energy-efficient and performance-optimized fan with high
                    capacity
                  </li>
                  <li className="p-0.5">
                    - smooth and easy-to-decontaminate housing
                  </li>
                  <li className="p-0.5">- light and sturdy housing</li>
                  <li className="p-0.5">- threefold sealed HEPA filter</li>
                  <li className="p-0.5">
                    - easy, very low-emission filter change in 3 minutes
                  </li>
                  <li className="p-0.5">
                    - automatic soft start, even after power failure
                  </li>
                  <li className="p-0.5">
                    - all operating control and functional elements are
                    shock-protected
                  </li>
                  <li className="p-0.5">
                    - stackable housings, thus low space requirement
                  </li>
                </ul>
              </span>
            </Desc>
          </SliderPanel>
          <SliderPanel className="md:ml-3 md:mt-3 md:mb-2 md:mr-3 index-slider">
            <div className="pt-5 md:pt-0 text-center">
              <Img
                fixed={props.Telealarm_Img}
                className="
                  rounded-full
                  md:rounded-tl-xl md:rounded-bl-xl md:rounded-tr-none md:rounded-br-none
                  img
                "
              />
            </div>
            <Desc className="w-full pb-5 md:pb-0 pl-5 pr-5 pt-1 md:max-h-72 md:overflow-y-auto">
              <h3
                className="
                  text-xl
                  pb-2
                  text-center md:text-left
                "
              >
                TELEALARM CONNECT
              </h3>
              <span className="text-sm">
                The device Telealarm GSM allows the notification of the person
                on charge in case of alarm or message by means of an integrated
                CSM phone. The connection and the handling are perfectly simple.
                For the use, you only need an active SIM Card. An integrated
                battery, which is automatically charged via mains voltage,
                guarantees a safe use also during outages. Together with the
                negative pressure monitor aircontrol or others signalling
                devices such as burglary detector, smoke and water detector, the
                Telealarm GSM offers a professional and reliable solution for
                the transmission of alarm.
              </span>
            </Desc>
          </SliderPanel>
        </Slider>
      </Panel>
    </div>
  )
}

export default ProductPalette
