import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import FirstTab from "../components/index/firstTab"
import SecondTab from "../components/index/secondTab"
import ProductPalette from "../components/index/productPalette"
import UseCases from "../components/index/useCases"

import useWindowWidth from "../components/useWindowWidth"

const SectionWrapper = ({ children }) => (
  <section className="mt-20 md:mt-20 md:mb-20">{children}</section>
)

export default function Home({ data }) {
  return (
    <Layout page={"index"}>
      <SectionWrapper>
        <FirstTab />
      </SectionWrapper>
      <SectionWrapper>
        <SecondTab
          R150School_Img={data?.R150School_Img?.childImageSharp?.fixed}
        />
      </SectionWrapper>
      <SectionWrapper>
        <ProductPalette
          R150Office_Img={data?.R150Office_Img?.childImageSharp?.fixed}
          G100_Img={data?.G100_Img?.childImageSharp?.fixed}
          S200_Img={data?.S200_Img?.childImageSharp?.fixed}
          Telealarm_Img={data?.Telealarm_Img?.childImageSharp?.fixed}
          width={useWindowWidth()}
        />
      </SectionWrapper>
      <SectionWrapper>
        <UseCases />
      </SectionWrapper>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    R150School_Img: file(relativePath: { eq: "products/r150school.jpg" }) {
      childImageSharp {
        fixed(width: 300, height: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    R150Office_Img: file(relativePath: { eq: "products/r150office.jpg" }) {
      childImageSharp {
        fixed(width: 300, height: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    G100_Img: file(relativePath: { eq: "products/g100.jpg" }) {
      childImageSharp {
        fixed(width: 300, height: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    S200_Img: file(relativePath: { eq: "products/s200.jpg" }) {
      childImageSharp {
        fixed(width: 300, height: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    Telealarm_Img: file(relativePath: { eq: "products/telealarm.png" }) {
      childImageSharp {
        fixed(width: 300, height: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
