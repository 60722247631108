import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"

const Panel = styled.div`
  & span {
    color: var(--text);
  }
`

const PicPanel = styled.div`
  & .pic-label {
    width: 150px;
    background: var(--blue);
  }
`

const useCases = () => (
  <StaticQuery
    query={graphql`
      query {
        Abbruch_Img: file(relativePath: { eq: "stock/abbruch.jpg" }) {
          childImageSharp {
            fixed(width: 150, height: 150) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        Mould_Img: file(relativePath: { eq: "stock/mould.jpg" }) {
          childImageSharp {
            fixed(width: 150, height: 150) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        Dust_Img: file(relativePath: { eq: "stock/dust.jpg" }) {
          childImageSharp {
            fixed(width: 150, height: 150) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        R150Office_Img: file(relativePath: { eq: "stock/r150office.jpg" }) {
          childImageSharp {
            fixed(width: 150, height: 150) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        Data_Img: file(relativePath: { eq: "stock/data.jpg" }) {
          childImageSharp {
            fixed(width: 150, height: 150) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        Management_Img: file(relativePath: { eq: "stock/management.jpg" }) {
          childImageSharp {
            fixed(width: 150, height: 150) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={data => (
      <div className="pl-4 pr-4 z-20 items-center">
        <div className="md:flex justify-center">
          <div>
            <Panel
              className="text-center relative pb-5"
              data-sal-duration="500"
              data-sal="slide-up"
              data-sal-easing="ease"
            >
              <span className="text-3xl block pb-5">Industry solutions</span>
              <span className="block w-72 mx-auto text-sm">
                We support a wide range of industries from different sectors in
                optimising the monitoring of their security and documentation
              </span>
            </Panel>
            <PicPanel className="flex text-white text-center justify-center">
              <div
                data-sal-duration="500"
                data-sal="slide-up"
                data-sal-easing="ease"
                className="relative"
              >
                <span className="">
                  <Img
                    fixed={data?.Abbruch_Img?.childImageSharp?.fixed}
                    className="w-1 m-1 img"
                  />
                </span>
                <p className="m-1 p-1 absolute bottom-0 left-0 text-center text-xs font-medium uppercase pic-label">
                  Asbestos
                </p>
              </div>
              <div
                data-sal-duration="500"
                data-sal="slide-up"
                data-sal-easing="ease"
                className="relative"
              >
                <span>
                  <Img
                    fixed={data?.Mould_Img?.childImageSharp?.fixed}
                    className="w-1 m-1 img"
                  />
                </span>
                <p className="m-1 p-1 absolute bottom-0 left-0 text-center text-xs font-medium uppercase pic-label">
                  Mold
                </p>
              </div>
            </PicPanel>
            <PicPanel className="flex text-white text-center justify-center">
              <div
                data-sal-duration="500"
                data-sal="slide-up"
                data-sal-easing="ease"
                className="relative"
              >
                <span>
                  <Img
                    fixed={data?.Dust_Img?.childImageSharp?.fixed}
                    objectFit="cover"
                    objectPosition="50% 50%"
                    className="w-1 m-1 img"
                  />
                </span>
                <p className="m-1 p-1 absolute bottom-0 left-0 text-center text-xs font-medium uppercase pic-label">
                  Dust
                </p>
              </div>
              <div
                data-sal-duration="500"
                data-sal="slide-up"
                data-sal-easing="ease"
                className="relative"
              >
                <span>
                  <Img
                    fixed={data?.R150Office_Img?.childImageSharp?.fixed}
                    objectFit="cover"
                    objectPosition="50% 50%"
                    className="w-1 m-1 img"
                  />
                </span>
                <p className="m-1 p-1 absolute bottom-0 left-0 text-center text-xs font-medium uppercase pic-label">
                  Clean Air
                </p>
              </div>
            </PicPanel>
          </div>
          <div className="w-20 hidden md:block" />
          <div>
            <Panel
              className="text-center relative pb-5 pt-10 md:pt-0"
              data-sal-duration="500"
              data-sal="slide-up"
              data-sal-easing="ease"
            >
              <span className="text-3xl block pb-5">Areas of application</span>
              <span className="block w-72 mx-auto text-sm">
                Our Application Programming Interface is already being used for
                a wide range of application areas
              </span>
            </Panel>
            <PicPanel className="flex text-white text-center justify-center">
              <div
                data-sal-duration="500"
                data-sal="slide-up"
                data-sal-easing="ease"
                className="relative"
              >
                <span>
                  <Img
                    fixed={data?.Abbruch_Img?.childImageSharp?.fixed}
                    className="w-1 m-1 img"
                  />
                </span>
                <p className="m-1 p-1 absolute bottom-0 left-0 text-center text-xs font-medium uppercase pic-label">
                  Alarming
                </p>
              </div>
              <div
                data-sal-duration="500"
                data-sal="slide-up"
                data-sal-easing="ease"
                className="relative"
              >
                <span>
                  <Img
                    fixed={data?.Data_Img?.childImageSharp?.fixed}
                    className="w-1 m-1 img"
                  />
                </span>
                <p className="m-1 p-1 absolute bottom-0 left-0 text-center text-xs font-medium uppercase pic-label">
                  Documentation
                </p>
              </div>
            </PicPanel>
            <PicPanel className="flex text-white text-center justify-center">
              <div
                data-sal-duration="500"
                data-sal="slide-up"
                data-sal-easing="ease"
                className="relative"
              >
                <span>
                  <Img
                    fixed={data?.Management_Img?.childImageSharp?.fixed}
                    className="w-1 m-1 img"
                  />
                </span>
                <p className="m-1 p-1 absolute bottom-0 left-0 text-center text-xs font-medium uppercase pic-label">
                  Management
                </p>
              </div>
              <div
                data-sal-duration="500"
                data-sal="slide-up"
                data-sal-easing="ease"
                className="relative"
              >
                <span>
                  <Img
                    fixed={data?.Management_Img?.childImageSharp?.fixed}
                    className="w-1 m-1 img"
                  />
                </span>
                <p className="m-1 p-1 absolute bottom-0 left-0 text-center text-xs font-medium uppercase pic-label">
                  Control
                </p>
              </div>
            </PicPanel>
          </div>
        </div>
      </div>
    )}
  />
)

export default useCases
