import React from "react"
import styled from "styled-components"
import sal from "sal.js"

import Fast from "../../assets/svg/fast.svg"
import Easy from "../../assets/svg/easy.svg"

const Panel = styled.div`
    & h3 {
        color: var(--text);
    }
    & p {
        color: var(--text);
    }
    & span {
        color: var(--text);
    }
    & .icon {
        fill: var(--text);
    }
    &:hover {
        & .icon {
            fill var(--blue);
            transform: scale(1.3);
            transition: transform 500ms;
        }
    }
`

export default function FirstTab(props) {

  React.useEffect(() => {
    sal()
  }, [])

  return (
    <div className="pl-4 pr-4 md:pl-20 md:pr-20 items-center">
      <Panel className="text-center relative pb-10 z-30">
        <span
          data-sal-duration="500"
          data-sal="slide-up"
          data-sal-easing="ease"
          className="text-2xl md:text-3xl"
        >
          Integrate the deconta connect products into your system
        </span>
      </Panel>
      <div className="flex">
        <Panel
          data-sal-duration="500"
          data-sal="slide-up"
          data-sal-easing="ease"
          className="w-full z-30"
        >
          <span className="">
            <Fast className="w-20 h-20 mx-auto icon" />
          </span>
          <h3 className="text-center pb-5 text-lg md:text-xl pt-5">
            Fast & reliable
          </h3>
          <p className="text-sm md:text-base">
            Fast and reliable. We offer you a fast response time
            and the highest reliability with our cloud infrastructure.
          </p>
        </Panel>
        <div className="w-20" />
        <Panel
          data-sal-duration="500"
          data-sal="slide-up"
          data-sal-easing="ease"
          className="w-full z-30"
        >
          <span>
            <Easy className="w-20 h-20 mx-auto icon" />
          </span>
          <h3 className="text-center pb-5 text-lg md:text-xl pt-5">
            Easy to integrate
          </h3>
          <p className="text-sm md:text-base">
            Through our Application Programming Interface, deconta-connect
            products can be integrated into your application
          </p>
        </Panel>
      </div>
    </div>
  )
}
