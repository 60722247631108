import React from "react"
import styled from "styled-components"
import sal from "sal.js"
import Img from "gatsby-image"
import Stepper from "@material-ui/core/Stepper"
import Step from "@material-ui/core/Step"
import StepLabel from "@material-ui/core/StepLabel"
import StepContent from "@material-ui/core/StepContent"

const ProductImage = styled(Img)`
  border-color: var(--blue);
`;

const StyledBg = styled.div`
  background: var(--surfaceBg);
  background-size: cover !important;
`;

const Panel = styled.div`
  & span {
    color: var(--text);
  }
  & .img {
    border-radius: none !important;
  }
`

function getSteps() {
  return [
    "Register on deconta-connect",
    "Link your device to your account",
    "Generate an API Key",
    "Integrate our API",
  ]
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return `Register on our web application www.deconta-connect.com`
    case 1:
      return `Easy linking by scanning a QR code on the device`
    case 2:
      return `You can retrieve your API Key in our web application at www.deconta-connect.com`
    case 3:
      return `Use our API endpoints easily & quickly in your individual application`
    default:
      return "Unknown step"
  }
}

const SecondTab = props => {
  const [activeStep, setActiveStep] = React.useState(0)
  const steps = getSteps()

  React.useEffect(() => {
    sal()
  }, [])

  return (
    <div
      className="pt-5 pb-5 ml-4 mr-4 md:ml-10 md:mr-10 relative deconta-stepper z-30"
      data-sal-duration="500"
      data-sal="slide-up"
      data-sal-easing="ease"
    >
      <StyledBg className="w-full absolute h-full rounded-xl left-0 top-0" />
      <Panel
        className="text-center relative pb-10 z-30"
      >
        <span className="text-3xl block pb-5">Register your device</span>
        <span className="block pl-5 pr-5 md:pl-32 md:pr-32 mx-auto text-sm">
          You can register your device in the deconta-connect system in just a few minutes.
          All you need to do is register on our platform and you can add your device to
          your account via the QR code.
        </span>
      </Panel>
      <div className="sm:flex items-center justify-center md:h-80">
        <div className="w-full max-w-xs mx-auto md:m-0">
          <ProductImage
            fixed={props.R150School_Img}
            className="rounded-full border-4 w-1 h-1"
          />
        </div>
        <div className="w-2" />
        <div className="w-full max-w-xs mx-auto md:m-0 z-30">
          <div className="z-30">
            <Stepper activeStep={activeStep} orientation="vertical" className="z-30">
              {steps.map((label, index) => (
                <Step
                  key={label}
                  onClick={() => setActiveStep(index) }
                  onMouseEnter={() => setActiveStep(index) }
                  className="z-30"
                >
                  <StepLabel>
                    <div className="text-base">{label}</div>
                  </StepLabel>
                  <StepContent transitionDuration={500}>
                    <div className="text-sm deconta-stepper-content">
                      {getStepContent(index)}
                    </div>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SecondTab
